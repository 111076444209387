import {
  AddProductsToCartFab,
  ProductListItemProps,
  ProductListItemRenderer,
} from '@graphcommerce/magento-product'
import {
  ProductListItemBundle,
  ProductListItemConfigurable,
  ProductListItemDownloadable,
  ProductListItemGrouped,
  ProductListItemSimple,
  ProductListItemVirtual,
} from '../ProductListItem'
import { ProductReviewSummary } from '@graphcommerce/magento-review'
import { ProductWishlistChip } from '@graphcommerce/magento-wishlist'
import { Typography, Link, Box } from '@mui/material'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { useArtistLink } from '../../hooks/Artist/useArtistLink'
import PageLink from 'next/link'

type ProductListItemCommonProps = Pick<
  ProductListItemProps,
  'sx' | 'subTitle' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
>

const { classes } = extendableComponent('ProductListItem', [
  'artist',
  'type',
  'typeLabel',
  'typeSize',
  'actions',
] as const)

const ArtistSubtitle = ({ label, slug }) => {
  const artistUrl = useArtistLink({ slug })

  return (
    label &&
    artistUrl && (
      <PageLink href={artistUrl} title={label ?? ''}>
        <Typography
          className={classes.artist}
          component='div'
          variant='body2'
          sx={{
            color: 'text.secondary',
          }}
        >
          by {label}
        </Typography>
      </PageLink>
    )
  )
}

const getCommonProps = (props: ProductListItemProps): ProductListItemCommonProps => {
  const { artist_option_label, artist_slug } = props

  return {
    subTitle: <ArtistSubtitle label={artist_option_label} slug={artist_slug} />,
    // bottomLeft: <ProductReviewSummary {...props} />,
    // topRight: <ProductWishlistChip {...props} />,
    // bottomRight: <AddProductsToCartFab sku={sku} />,
  }
}

const getCommonChildren = (props: ProductListItemProps) => {
  const {
    artwork_type_option_label,
    artwork_complete_size_unframed_height,
    artwork_complete_size_unframed_width,
  } = props

  return (
    <>
      {artwork_type_option_label && (
        <Typography
          className={classes.type}
          component='div'
          variant='body2'
          sx={{
            gridArea: 'type',
            color: 'text.secondary',
          }}
        >
          <span className={classes.typeLabel}>{artwork_type_option_label}</span>
          <span className={classes.typeSize}>
            {artwork_complete_size_unframed_height && artwork_complete_size_unframed_width && (
              <>
                <span>{' - '}</span>
                <span data-attr='artwork_complete_size_unframed_height'>{`${artwork_complete_size_unframed_height.replace(
                  '.00',
                  '',
                )}`}</span>
                <span>{' x '}</span>
                <span data-attr='artwork_complete_size_unframed_width'>{`${artwork_complete_size_unframed_width.replace(
                  '.00',
                  '',
                )}`}</span>
                <span>{' cm'}</span>
              </>
            )}
          </span>
        </Typography>
      )}
      <Box
        className={classes.actions}
        sx={(theme) => ({
          gridArea: 'actions',
          padding: `${theme.spacings.xxxxs} 0`,
          color: 'text.secondary',
        })}
      >
        <ProductWishlistChip
          buttonProps={{
            size: 'small',
          }}
          sx={{
            padding: '0',
          }}
          {...props}
        />
      </Box>
    </>
  )
}

export const productListRenderer: ProductListItemRenderer = {
  SimpleProduct: (props) => (
    <ProductListItemSimple {...props} {...getCommonProps(props)}>
      {getCommonChildren(props)}
    </ProductListItemSimple>
  ),
  ConfigurableProduct: (props) => (
    <ProductListItemConfigurable
      {...props}
      {...getCommonProps(props)}
      swatchLocations={{
        topLeft: [],
        topRight: [], // ['size']
        bottomLeft: [],
        bottomRight: ['dominant_color'],
      }}
    >
      {getCommonChildren(props)}
    </ProductListItemConfigurable>
  ),
  BundleProduct: (props) => (
    <ProductListItemBundle {...props} {...getCommonProps(props)}>
      {getCommonChildren(props)}
    </ProductListItemBundle>
  ),
  VirtualProduct: (props) => (
    <ProductListItemVirtual {...props} {...getCommonProps(props)}>
      {getCommonChildren(props)}
    </ProductListItemVirtual>
  ),
  DownloadableProduct: (props) => (
    <ProductListItemDownloadable {...props} {...getCommonProps(props)}>
      {getCommonChildren(props)}
    </ProductListItemDownloadable>
  ),
  GroupedProduct: (props) => (
    <ProductListItemGrouped {...props} {...getCommonProps(props)}>
      {getCommonChildren(props)}
    </ProductListItemGrouped>
  ),
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore GiftCardProduct is only available in Commerce
  // GiftCardProduct: (props) => (
  //   <ProductListItem {...props} subTitle={<Subtitle>BY GC</Subtitle>} aspectRatio={[1, 1]} />
  // ),
}

import { Box, SxProps, Theme, Typography } from '@mui/material';

type ProductBadgeProps = {
  text: string
  sx?: SxProps<Theme>
}

export function ProductBadge(props: ProductBadgeProps) {
  const { text, sx = [] } = props

  return (
    <Box
      sx={[
        (theme) => ({
          position: 'absolute',
          top: '10px',
          right: '20px',
          alignSelf: 'center',
          justifySelf: 'center',
          textAlign: 'center',
          padding: '5px 13px',
          backgroundColor: theme.palette.background.dark,
          color: theme.palette.primary.contrastText,
          textTransform: 'uppercase'
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant='body2' component='div'>
        {text}
      </Typography>
    </Box>
  )
}
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { RenderType } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { ProductItemsGridProps } from '@graphcommerce/magento-product'
import { Masonry } from '@mui/lab'

export type ProductItemsMasonryProps = ProductItemsGridProps

const name = 'ProductListItemsMasonry' as const
const parts = ['root', 'masonry'] as const
const { classes } = extendableComponent(name, parts)

export function ProductListItemsMasonry(props: ProductItemsMasonryProps) {
  const {
    items,
    sx = [],
    renderers,
    loadingEager = 0,
    size = 'normal',
    titleComponent,
    onClick,
  } = props

  const displayItems = items?.filter((item) => !!item) as Array<
    NonNullable<NonNullable<typeof items>[0]>
  >

  const masonrySpacing = 5

  return (
    <Box
      className={classes.root}
      sx={{
        display: 'flex',
        flexDirection: 'column',

        '& .ProductListItem-root': {
          '& a': {
            textDecoration: 'none',
          },
        },

        '& .ProductListItem-imageContainer': {
          padding: '0 !important',

          '& .ProductListItem-image': {
            position: 'static',
            width: '100%',
            height: 'auto',
            objectFit: 'initial',
          },
        },
      }}
    >
      <Masonry
        className={classes.masonry}
        columns={{ xs: 2, slim: 2, md: 3, mdlg: 4 }}
        spacing={masonrySpacing}
        defaultHeight={450}
        defaultColumns={4}
        defaultSpacing={masonrySpacing}
        sx={[
          {
            width: 'auto',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {displayItems.map((item, idx) => (
          <div key={item.uid ?? ''}>
            <RenderType
              renderer={renderers}
              sizes={
                size === 'small'
                  ? { 0: '100vw', 354: '50vw', 675: '30vw', 1255: '23vw', 1500: '337px' }
                  : { 0: '100vw', 367: '48vw', 994: '30vw', 1590: '23vw', 1920: '443px' }
              }
              {...item}
              loading={loadingEager > idx ? 'eager' : 'lazy'}
              titleComponent={titleComponent}
              onClick={onClick}
              noReport
            />
          </div>
        ))}
      </Masonry>
    </Box>
  )
}

import { Image, ImageProps } from '@graphcommerce/image'
import { responsiveVal, extendableComponent, useNumberFormat } from '@graphcommerce/next-ui'
import { Typography, Box, styled, SxProps, Theme, useEventCallback, Link } from '@mui/material'
import React from 'react'
import { ProductListItemFragment } from '@graphcommerce/magento-product/Api/ProductListItem.gql'
import { useProductLink } from '@graphcommerce/magento-product/hooks/useProductLink'
import { ProductListPrice } from '@graphcommerce/magento-product/components/ProductListPrice/ProductListPrice'
import { ProductBadge } from '../Product/ProductBadge'
import { useRouter } from 'next/router'

const { classes, selectors } = extendableComponent('ProductListItem', [
  'root',
  'item',
  'title',
  'titleContainer',
  'subtitle',
  'price',
  'overlayItems',
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
  'imageContainer',
  'placeholder',
  'image',
  'imageHover',
  'discount',
  'actions',
] as const)

export type OverlayAreaKeys = 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight'

export type OverlayAreas = Partial<Record<OverlayAreaKeys, React.ReactNode>>

type StyleProps = {
  aspectRatio?: [number, number]
  imageOnly?: boolean
}

type BaseProps = { subTitle?: React.ReactNode; children?: React.ReactNode } & StyleProps &
  OverlayAreas &
  ProductListItemFragment &
  Pick<ImageProps, 'loading' | 'sizes' | 'dontReportWronglySizedImages'>

export type ProductListItemProps = BaseProps & {
  sx?: SxProps<Theme>
  titleComponent?: React.ElementType
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>, item: ProductListItemFragment) => void
}

const StyledImage = styled(Image)({})

export function ProductListItem(props: ProductListItemProps) {
  const router = useRouter()

  const {
    sku,
    subTitle,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    small_image,
    image_hover,
    name,
    artist_option_label,
    price_range,
    stock_status,
    children,
    imageOnly = false,
    loading,
    sizes,
    dontReportWronglySizedImages,
    // aspectRatio = [4, 3],
    titleComponent = 'span',
    sx = [],
    onClick,
  } = props

  const hasHoverImage = image_hover?.url && !image_hover?.url.match(/\/image_hover\./)

  // const handleClick = useEventCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
  //   e.preventDefault()
  //   if (onClick) {
  //     onClick(e, props)
  //   }
  // })

  const productLink = useProductLink(props)
  // const discount = Math.floor(price_range.minimum_price.discount?.percent_off ?? 0)
  //
  // const formatter = useNumberFormat({ style: 'percent', maximumFractionDigits: 1 })

  const imageAlt =
    (!!name && !!artist_option_label ? `${name} by ${artist_option_label}` : name) ?? ''

  return (
    <Box
      sx={[
        {
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          gridTemplateRows: 'auto auto 1fr',
          gridTemplateAreas: ['"image image"', '"type actions"', '"title title"'].join(' '),
          position: 'relative',
          height: '100%',

          '& a': {
            textDecoration: 'none',
          },

          '&:hover': hasHoverImage
            ? {
                [selectors.image]: {
                  opacity: 0,
                  minHeight: 200,
                },

                [selectors.imageHover]: {
                  opacity: 1,
                },
              }
            : {},
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={classes.root}
    >
      {stock_status === 'OUT_OF_STOCK' && (
        <ProductBadge
          text='Sold'
          sx={{
            zIndex: '1',
            top: '5px',
            right: '5px',
          }}
        />
      )}
      <Link
        href={productLink}
        title={name ?? ''}
        sx={{
          gridArea: 'image',
          display: 'grid',
          bgcolor: 'background.image',
          overflow: 'hidden',
          padding: responsiveVal(8, 12),
          position: 'relative',
          minHeight: '80px',
          marginBottom: '10px',

          [`${selectors.image}, ${selectors.imageHover}`]: {
            transition: 'opacity 150ms ease-in-out',
          },

          '& > picture': {
            gridArea: `1 / 1 / 3 / 3`,
            display: 'flex',
          },
        }}
        className={classes.imageContainer}
      >
        {!!small_image && (
          <StyledImage
            layout='fill'
            width={1}
            height={1}
            sizes={sizes}
            dontReportWronglySizedImages={dontReportWronglySizedImages}
            src={small_image.url ?? ''}
            alt={imageAlt}
            className={classes.image}
            loading={loading}
          />
        )}
        {hasHoverImage && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <StyledImage
              layout='fill'
              width={1}
              height={1}
              sizes={sizes}
              dontReportWronglySizedImages={dontReportWronglySizedImages}
              src={image_hover.url ?? ''}
              alt={`${imageAlt} - Secondary Image`}
              className={classes.imageHover}
              loading={loading}
              sx={{ objectFit: 'contain', opacity: 0 }}
            />
          </Box>
        )}
      </Link>

      {!imageOnly && (
        <Box
          className={classes.titleContainer}
          sx={{
            gridArea: 'title',
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            gridTemplateRows: 'auto auto 1fr',
            gridTemplateAreas: [
              '"type type actions"',
              '"title title actions"',
              '"subtitle price price"',
            ].join(' '),
            alignItems: 'start',
            marginTop: 0,
            columnGap: 1,
            justifyContent: 'space-between',

            '& .ProductListPrice-root': {
              alignSelf: 'end',
            },
          }}
        >
          <Link sx={{ gridArea: 'title' }} href={productLink} title={name ?? ''}>
            <Typography
              component={titleComponent}
              variant='body1'
              sx={{
                display: 'block',
                color: 'text.primary',
                maxWidth: '100%',
              }}
              className={classes.title}
            >
              {name}
              {topRight}
            </Typography>
          </Link>
          <Box sx={{ gridArea: 'subtitle' }} className={classes.subtitle}>
            {subTitle}
          </Box>

          <ProductListPrice
            {...({
              ...price_range.minimum_price,
              regular_price: {
                ...price_range.minimum_price.regular_price,
                formatOptions: {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                },
              },
              final_price: {
                ...price_range.minimum_price.final_price,
                formatOptions: {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                },
              },
            } as unknown as typeof price_range.minimum_price)}
            sx={{
              gridArea: 'price',
              textAlign: 'right',
              justifySelf: { sm: 'flex-end' },
            }}
          />
          {children}
        </Box>
      )}
    </Box>
  )
}

ProductListItem.selectors = { ...selectors, ...ProductListPrice.selectors }

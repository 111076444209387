import { ProductListItem, ProductListItemProps } from './ProductListItem'
import { ProductListItemBundleFragment } from '@graphcommerce/magento-product-bundle/ProductListItemBundle.gql'

export type ProdustListItemBundleProps = ProductListItemBundleFragment & ProductListItemProps

export function ProductListItemBundle(props: ProdustListItemBundleProps) {
  const { children } = props

  return <ProductListItem {...props}>{children}</ProductListItem>
}

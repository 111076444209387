import { ProductListItemsBase, ProductItemsGridProps } from '@graphcommerce/magento-product'
import { productListRenderer } from './productListRenderer'
import { ProductListItemsMasonry } from './ProductListItemsMasonry'

export type ProductListItemsProps = Omit<ProductItemsGridProps, 'renderers'> & {
  masonry?: boolean
}

export function ProductListItems(props: ProductListItemsProps) {
  const { masonry } = props

  return (
    <>
      {!masonry && <ProductListItemsBase renderers={productListRenderer} {...props} />}
      {masonry && <ProductListItemsMasonry renderers={productListRenderer} {...props} />}
    </>
  )
}

import { ArtistLinkFragment } from '../../components/Artist/ArtistLink/ArtistLinkFragment.gql'

export type ArtistLinkProps = ArtistLinkFragment

export function artistLink(props: ArtistLinkProps) {
  const { slug } = props
  return `/${slug}`
}

export function useArtistLink(props: ArtistLinkProps) {
  return artistLink(props)
}
